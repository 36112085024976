"use client";

import uniq from "lodash/uniq";
import { useMemo, useEffect, useCallback, useState } from "react";

import { paths } from "src/routes/paths";
import { useRouter } from "src/routes/hooks";

import { getStorage, useLocalStorage } from "src/hooks/use-local-storage";

import { PRODUCT_CHECKOUT_STEPS } from "src/_mock/_product";

import { IAddressItem } from "src/types/address";
import { ICheckoutItem } from "src/types/checkout";

import { CheckoutContext } from "./checkout-context";
import { useUpdateCartProducts } from "src/api/product";
import { getCookie, setCookie } from "cookies-next";
import { poster } from "src/utils/axios";
import { IOrderCreationBody } from "src/types/orders";

// ----------------------------------------------------------------------

const STORAGE_KEY = "checkout";

const initialState = {
  activeStep: 0,
  items: [],
  subTotal: 0,
  total: 0,
  discount: 0,
  discountType: "",
  shipping: 0,
  billing: null,
  totalItems: 0,
  delivery: {
    method: "",
    pickupPointId: "",
    price: 0,
  },
  lastCheckoutProgress: new Date(),
};

type Props = {
  children: React.ReactNode;
};

/* function hasAnHourPassed(lastTime: any) {
  const currentTime = new Date();
  // 1 hour in milliseconds
  const oneHourInMs = 60 * 60 * 1000;
  const timeDifference = currentTime - new Date(lastTime);
  return timeDifference >= oneHourInMs;
} */
export function CheckoutProvider({ children }: Props) {
  const router = useRouter();

  const [stateInitialized, setStateInitialized] = useState(false);
  const { state, update, reset } = useLocalStorage(STORAGE_KEY, initialState);

  const checkIfCartIsCompatibileWithDatabase = () => {
    if (!stateInitialized) {
      (async function () {
        const checkedProducts = await useUpdateCartProducts(
          state.items.map((item: ICheckoutItem) => parseInt(item.id))
        );
        // console.log(checkedProducts);
        // console.log(state.items);
        // Remove items that are no longer in db
        if (checkedProducts) {
          const unmatchedItems = state.items.filter(
            (item: any) =>
              !checkedProducts.some(
                (checkedItem: any) => checkedItem.id === parseInt(item.id)
              )
          );
          //  console.log("state.items", state.items);
          //  console.log("unmatchedItems", unmatchedItems);
          if (unmatchedItems.length > 0) {
            unmatchedItems.forEach((item: any) => {
              //  console.log(`Item ${item.id} is no longer in database`);
              onDeleteCart(item.id);
            });
          }
        }

        // Update quantity of all items
        if (checkedProducts) {
          state.items.forEach((item: ICheckoutItem) => {
            const checkedProduct = checkedProducts.find(
              (product: any) => product.id === parseInt(item.id)
            );
            if (checkedProduct) {
              if (item.quantity > checkedProduct.quantity) {
                /*  console.log(
                    `Item ${item.id} quantity is greater than in database`
                  );*/
                onUpdateQuantity(item.id, checkedProduct.quantity);
              }
            }
          });
        }
        // Update price of all items
        if (checkedProducts) {
          state.items.forEach((item: ICheckoutItem) => {
            const checkedProduct = checkedProducts.find(
              (product: any) => product.id === parseInt(item.id)
            );
            if (checkedProduct) {
              if (item.price !== parseInt(checkedProduct.price)) {
                /* console.log(
                  `Item ${item.id} price is different than in database`
                ); */
                onUpdatePrice(item.id, parseInt(checkedProduct.price));
              }
            }
          });
        }
        setStateInitialized(true);
        // console.log("Cart checked successfuly");
      })();
    }
  };

  const onGetCart = useCallback(() => {
    checkIfCartIsCompatibileWithDatabase();
    const totalItems: number = state.items.reduce(
      (total: number, item: ICheckoutItem) => total + item.quantity,
      0
    );

    const subTotal: number = state.items.reduce(
      (total: number, item: ICheckoutItem) =>
        total + item.quantity * item.price,
      0
    );
    const billingFromCookie = getCookie("billing");
    const billing = billingFromCookie
      ? JSON.parse(`${billingFromCookie}`)
      : null;
    update("subTotal", subTotal);
    update("totalItems", totalItems);
    update("billing", state.activeStep === 1 ? null : billing);
    update("discount", state.items.length ? state.discount : 0);
    update("shipping", state.items.length ? state.shipping : 0);
    const calculateDiscount = state.discountType === 'percent' ? subTotal * state.discount / 100 : state.discount;
    update("total", state.subTotal - calculateDiscount + state.shipping > 0 ? state.subTotal - calculateDiscount + state.shipping : 0);
    /* if (hasAnHourPassed(state.lastCheckoutProgress)) {
      update('activeStep', 0);
    } */
  }, [
    state.items,
    state.activeStep,
    state.discount,
    state.shipping,
    state.subTotal,
  ]);

  useEffect(() => {
    const restored = getStorage(STORAGE_KEY);
    if (restored) {
      onGetCart();
    }
  }, [onGetCart]);

  const onAddToCart = useCallback(
    (newItem: ICheckoutItem) => {
      if (newItem.available < 1) return;
      const updatedItems: ICheckoutItem[] = state.items.map(
        (item: ICheckoutItem) => {
          if (item.id === newItem.id) {
            return {
              ...item,
              colors: uniq([...item.colors, ...newItem.colors]),
              quantity: item.quantity + 1,
            };
          }
          return item;
        }
      );

      if (!updatedItems.some((item: ICheckoutItem) => item.id === newItem.id)) {
        updatedItems.push(newItem);
      }

      update("items", updatedItems);
    },
    [update, state.items]
  );

  const onDeleteCart = useCallback(
    (itemId: string) => {
      const updatedItems = state.items.filter(
        (item: ICheckoutItem) => item.id !== itemId
      );

      update("items", updatedItems);
      if (updatedItems.length === 0) {
        onNextStep();
        onResetWithoutCompleted();
      }
    },
    [update, state.items]
  );

  const onBackStep = useCallback(() => {
    update("lastCheckoutProgress", new Date());
    update("activeStep", state.activeStep - 1);
  }, [update, state.activeStep]);

  const onNextStep = useCallback(() => {
    update("lastCheckoutProgress", new Date());
    update("activeStep", state.activeStep + 1);
  }, [update, state.activeStep]);

  const onGotoStep = useCallback(
    (step: number) => {
      update("activeStep", step);
    },
    [update]
  );

  const onIncreaseQuantity = useCallback(
    (itemId: string) => {
      const updatedItems = state.items.map((item: ICheckoutItem) => {
        if (item.id === itemId) {
          return {
            ...item,
            quantity: item.quantity + 1,
          };
        }
        return item;
      });

      update("items", updatedItems);
    },
    [update, state.items]
  );

  const onDecreaseQuantity = useCallback(
    (itemId: string) => {
      const updatedItems = state.items.map((item: ICheckoutItem) => {
        if (item.id === itemId) {
          return {
            ...item,
            quantity: item.quantity - 1,
          };
        }
        return item;
      });

      update("items", updatedItems);
    },
    [update, state.items]
  );

  const onUpdateQuantity = useCallback(
    (itemId: string, newQuantity: number) => {
      const updatedItems = state.items.map((item: ICheckoutItem) => {
        if (item.id === itemId) {
          return {
            ...item,
            quantity: newQuantity,
          };
        }
        return item;
      });

      update("items", updatedItems);
    },
    [update, state.items]
  );

  const onUpdatePrice = useCallback(
    (itemId: string, newPrice: number) => {
      const updatedItems = state.items.map((item: ICheckoutItem) => {
        if (item.id === itemId) {
          return {
            ...item,
            price: newPrice,
          };
        }
        return item;
      });

      update("items", updatedItems);
    },
    [update, state.items]
  );

  const onCreateBilling = useCallback(
    (address: IAddressItem) => {
      setCookie("billing", address);
      update("billing", address);
      onGetCart();
      onNextStep();
    },
    [onNextStep, update, setCookie]
  );

  const onApplyDiscount = useCallback(
    (discount: number, type: string) => {
      update("discount", discount);
      update("discountType", type);
    },
    [update]
  );

  const onApplyShipping = useCallback(
    (shipping: number) => {
      update("shipping", shipping);
    },
    [update]
  );

  const onApplyDelivery = useCallback(
    ({
      method,
      price,
      pickupPointId,
      id,
    }: {
      method: string;
      price: number;
      pickupPointId: string;
      id: number;
    }) => {
      update("delivery", {
        method,
        price,
        pickupPointId,
        id,
      });
    },
    [update]
  );

  const onDeleteDelivery = useCallback(() => {
    update("delivery", undefined);
  },
    [update]
  );

  const createOrder = useCallback(
    async ({ order }: { order: IOrderCreationBody }) => {
      return poster("/orders", order);
    },
    []
  );

  const createStripeOrderIntent = useCallback(
    async (order: IOrderCreationBody) => {
      return poster("/orders/stripe-intent", order);
    },
    []
  );

  const completed = state.activeStep === PRODUCT_CHECKOUT_STEPS.length;

  // Reset
  const onReset = useCallback(() => {
    if (completed) {
      reset();
      router.replace("/");
    }
  }, [completed, reset, router]);

  const onResetWithoutCompleted = useCallback(() => {
    reset();
  }, [reset]);

  const onNavigateToRoot = useCallback(() => {
    router.replace("/");
  }, [router]);

  const memoizedValue = useMemo(
    () => ({
      ...state,
      completed,
      //
      onAddToCart,
      onDeleteCart,
      //
      onIncreaseQuantity,
      onDecreaseQuantity,
      //
      onCreateBilling,
      onApplyDiscount,
      onApplyShipping,
      onApplyDelivery,
      onDeleteDelivery,
      //
      onBackStep,
      onNextStep,
      onGotoStep,
      //
      onReset,
      //
      createOrder,
      createStripeOrderIntent,
      onNavigateToRoot,
      onResetWithoutCompleted,
    }),
    [
      completed,
      onAddToCart,
      onApplyDiscount,
      onApplyShipping,
      onBackStep,
      onCreateBilling,
      onDecreaseQuantity,
      onDeleteCart,
      onGotoStep,
      onIncreaseQuantity,
      onNextStep,
      onReset,
      onDeleteDelivery,
      state,
      createOrder,
      onApplyDelivery,
      createStripeOrderIntent,
      onNavigateToRoot,
      onResetWithoutCompleted,
    ]
  );

  return (
    <CheckoutContext.Provider value={memoizedValue}>
      {children}
    </CheckoutContext.Provider>
  );
}
